export const LOGIN = '/login';
export const HOME = '/home';
export const PROFILE = '/profile';
export const ANALYSIS = '/analysis-history';
export const QUESTIONNAIRE = '/questionnaire';
export const REGISTERATION = '/registration';
export const CAMERA = '/camera';
export const RESULT = `${ANALYSIS}/result`;
export const HEALTH_TIPS = `${RESULT}/health-tips`;
export const HEALTH_TIPS_DIS = `${HEALTH_TIPS}/:id`;
export const RESULT_PUBLIC = '/shared-result/:batchId';
